class Helper {
    mapFields(oldValue, newValue) {
        for (var prop in oldValue) {
            if (newValue.hasOwnProperty(prop)) {
                oldValue[prop] = newValue[prop];
            }
        }
    }

    userAvatar(avatar) {
        if (avatar) {
            return (
                process.env.VUE_APP_ImagesPath +
                "/avatars/" +
                avatar
            );
        }
        return "";
    }

    isNumber(val){
        return typeof val === 'number' && !isNaN(val);
    }

    cleanPhoneNumber(phoneNumber) {
        if (!phoneNumber)
            return phoneNumber;


        phoneNumber = phoneNumber.trim();
        if (phoneNumber.startsWith("8")) {
            phoneNumber = "+7" + phoneNumber.substring(1);
        }
        return phoneNumber.replace(/^(\+)|\D/g, "$1");
    }
}

export default new Helper();