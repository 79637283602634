import axios from 'axios';

class ChatService {
  getUserGlobalChatUrl() {
    return axios.get("Chat/GetUserGlobalChatUrl");
  }

  getCustomerChatUrl(customerId) {
    return axios.get("Chat/GetCustomerChatUrl", {params: {customerId}});
  }

  getLeadChatUrl(leadId) {
    return axios.get("Chat/GetLeadChatUrl", {params: {leadId}});
  }

  getUserUnansweredCount() {
    return axios.get("Chat/GetUserUnansweredCount");
  }
}

export default new ChatService();