<template>
  <ul v-if="errors && errors.length > 0" style="padding-inline-start: 20px;">
    <li
      class="error"
      v-for="item in errors"
      v-bind:key="item"
    >{{item}}</li>
  </ul>
</template>

<script>
export default {
  name: "ValidationField",
  props: {
    errors: Array
  }
};
</script>