<template>
  <div class="row border-bottom">
    <nav class="navbar navbar-static-top" role="navigation" style="margin-bottom: 0">
      <div class="navbar-header">
        <a class="navbar-minimalize minimalize-styl-2 btn btn-primary" href="#" @click="switchView">
          <i class="fa fa-bars"></i>
        </a>
      </div>
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <strong>
            <b-alert v-if="expiredLicenseCountDays > 0 && expiredLicenseCountDays <= 3" style="margin-bottom: 0"
              variant="warning" dismissible show>
              Срок действия вашей лицензии истекает через
              {{ expiredLicenseCountDays }} {{ dayTitle() }}
            </b-alert>
            <b-alert v-if="expiredLicenseCountDays == 0" style="margin-bottom: 0" variant="danger" dismissible show>
              Срок действия вашей лицензии истекает сегодня
            </b-alert>
            <b-alert v-else-if="expiredLicenseCountDays < 0" style="margin-bottom: 0" variant="danger" show>
              <div v-if="expiredLicenseCountDays < 0">
                <strong>Срок действия вашей лицензии истёк</strong>
              </div>
            </b-alert>
            <b-alert v-if="freeSpace < 0.05" style="margin-bottom: 0" variant="warning" show>
              <strong> Внимание, заказничвается место на файловом хранилище. Для увеличения лимита напишите запрос в мессенджер на почту<a href="mailto:info@curs24.online" class="text-navy">info@curs24.online</a></strong>
            </b-alert>
            <b-alert v-else-if="freeSpace < 0" style="margin-bottom: 0" variant="danger" show>
              <strong> Превышен лимит по объему файлового хранилища. Для увеличения лимита напишите запрос в мессенджер на почту<a href="mailto:info@curs24.online" class="text-navy">info@curs24.online</a></strong>
            </b-alert>
          </strong>
        </li>
        <li v-if="userPermissions.IsAllowCustomersChatting" class="mr-2">
          <Chat></Chat>
        </li>
        <li @click="showProfile()">
          <avatar v-model="avatarUrl" style="cursor: pointer;" />
        </li>
        <li>
          <router-link :to="{
            name: 'profile',
          }">{{ currentUser.name }}</router-link>
        </li>
        <li>
          <a href @click="logOut"> <i class="fa fa-sign-out"></i> Выйти </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import moment from "moment";
import Chat from "@/components/Chat/Chat"
import Const from "@/models/const";

export default {
  name: "TopBar",
  components: {Chat},
  props: {},
  data() {
    return {
      companyInfo: {},
      userPermissions: {
        IsAllowCustomersChatting: false,
      },
      permissions: {
        AllowCustomerMessaging: false,
      },
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
    },
    switchView() {
      document.body.classList.toggle("mini-navbar");
    },
    dayTitle() {
      if (this.expiredLicenseCountDays == 1) return "день";
      else if (
        this.expiredLicenseCountDays >= 2 &&
        this.expiredLicenseCountDays <= 4
      )
        return "дня";
      return "дней";
    },
    showProfile() {
      if (this.$route.name !== "profile") {
        this.$router.push({ name: "profile", });
      }
    },
  },
  computed: {
    expiredLicenseCountDays() {
      if (!this.companyInfo || !this.companyInfo.expirationDate)
        return 30;            
      return moment(String(this.companyInfo.expirationDate)).diff(
        moment(),
        "days"
      );
    },
    freeSpace() {
      return this.companyInfo.maxSizeAttachment - this.companyInfo.totalSizeAttachment;
    },
    currentUser() {
      if (!this.$store.state.auth.user) {
        return "";
      }
      return this.$store.state.auth.user;
    },
    avatarUrl() {
      return this.$store.state.auth.user.avatar;
    },
  },
  mounted() {
    this.companyInfo = JSON.parse(localStorage.getItem("company-info"));
    this.$root.$on("updateUser", function (id) { });

    if (this.companyInfo.permissions) {
      this.permissions.AllowCustomerMessaging =
          this.companyInfo.permissions.includes(Const.permissionsDictionary.AllowCustomerMessaging.systemName);
    }

    const userPermissions = JSON.parse(localStorage.getItem("user-permissions"));
    if (userPermissions?.hasOwnProperty(Const.UserPermissionsDictionary.IsAllowCustomersChatting.systemName))
    {
      this.userPermissions.IsAllowCustomersChatting =
          userPermissions[Const.UserPermissionsDictionary.IsAllowCustomersChatting.systemName];
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
