import axios from 'axios';

class Const {
    getAllRoles() {
        return [
            { role: "Admin", name: "Владелец компании" },
            { role: "Designer", name: "Дизайнер" },
            { role: "Decorator", name: "Декоратор" },
            { role: "Seamstress", name: "Швея" },
            { role: "Driver", name: "Водитель" },
            { role: "Installer", name: "Монтажник" },
            { role: "Storekeeper", name: "Комплектовщик" },
            { role: "ProductionDirector", name: "Руководитель производства" },
            { role: "Editor", name: "Редактор справочников" },
        ];
    }

    getRoleName(role) {
        var result = this.getAllRoles().filter(item => {
            return item.role === role;
        });
        if (result.length > 0)
            return result[0].name;
        return '';
    }

    getRoleById(role) {
        var result = this.getAllRoles().filter(item => {
            return item.role === role;
        });
        if (result.length > 0)
            return result[0];
        return [];
    }

    getTimeZones() {
        return [
            { id: 2, text: "(UTC+02:00) Калининград, Советск, Ченяховск, Гусев, Светлый" },
            { id: 3, text: "(UTC+03:00) Москва, Санк-Петергург, Липецк, Пенза, Ставрополь" },
            { id: 4, text: "(UTC+04:00) Астрахань, Волгоград, Самара, Саратов, Ульяновск" },
            { id: 5, text: "(UTC+05:00) Уфа, Пермь, Екатеринбург, Тюмень, Магнитогорск" },
            { id: 6, text: "(UTC+06:00) Омск, Тара, Исилькуль, Калачинск" },
            { id: 7, text: "(UTC+07:00) Абакан, Барнаул, Красноярск, Новосибирск, Томск" },
            { id: 8, text: "(UTC+08:00) Улан-Удэ, Иркутск, Братск, Ангарск, Усть-Илимск" },
            { id: 9, text: "(UTC+09:00) Якутск, Чита, Красноармейск, Благовещенск, Тында" },
            { id: 10, text: "(UTC+10:00) Верхоянск, Владивосток, Хабаровск, Комсомольск-на-Амуре, Биробиджан" },
            { id: 11, text: "(UTC+11:00) Среднеколмск, Магадан, Южно-Сахалинск, Южно-Курильск, Курильск" },
            { id: 12, text: "(UTC+12:00) Петропавловск-Камчатский, Новый Уренгой, Усть-Камчатск, Нагорный, Анадырь" }
        ]
    }

    getTimeZoneById(id) {
        let zone = this.getTimeZones().find(item => item.id == id);
        if (!zone)
            return '';
        return zone;
    }

    getFieldPrecision(fieldType) {
        switch (fieldType) {
            case "numeric":
                return 0;
            case "float":
                return 4;
            default:
                return null;
        }
    }

    getPrintedForms() {
        return {
            ShortCommercialOffer: {
                id: "ShortCommercialOffer",
                name: "Сокращённое комерческое предложение",
            },
            FullCommercialOffer: {
                id: "FullCommercialOffer",
                name: "Полное коммерческое предложение",
            },
            CustomFullCommercialOffer: {
                id: "CustomFullCommercialOffer",
                name: "Коммерческое предложение - личное",
            },
            Contract: {
                id: "Contract",
                name: "Договор с заказчиком",
            },
            Invoice: {
                id: "Invoice",
                name: "Счёт на оплату",
            },
            TORG12: {
                id: "TORG12",
                name: "Товарная накладная (ТОРГ-12)",
            },
            ActOfCompletedWorks: {
                id: "ActOfCompletedWorks",
                name: "Акт выполненных работ"
            },
            PerformerProductionPlan: {
                id: "PerformerProductionPlan",
                name: "План работ производства",
            },
            PaymentDocument: {
                id: "PaymentDocument",
                name: "Приходный кассовый ордер",
            },
            OrderBlank: {
                id: "OrderBlank",
                name: "Бланк заказа",
            },
        }
    }

    getDaysOfWeekShort() {
        return {
            1: "пн",
            2: "вт",
            3: "ср",
            4: "чт",
            5: "пт",
            6: "сб",
            7: "вс",
        }
    }

    permissionsDictionary = {
        AllowMultipleVersionsPrintedDocumentsMaintained:{ systemName: "AllowMultipleVersionsPrintedDocumentsMaintained", description:"Разрешить ведение нескольких вариантов печатных документов" },
        AllowGenerationLiveCommercialOffer:{ systemName: "AllowGenerationLiveCommercialOffer", description:"Разрешить формирование живого коммерческого предложения" },
        AllowOnlinePayment:{ systemName: "AllowOnlinePayment", description:"Разрешить онлайн-оплату" },
        AllowAccrualSalary:{ systemName: "AllowAccrualSalary", description:"Разрешить начисление зарплаты" },
        AllowStockProduct:{ systemName: "AllowStockProduct", description:"Разрешить складской учёт товаров" },
        AllowUnlimitedOrganizations:{ systemName: "AllowUnlimitedOrganizations", description:"Разрешить неограниченное количество организаций" },
        AllowLeadsIntegration:{ systemName: "AllowLeadsIntegration", description:"Разрешить интеграцию для получения лидов" },
        AllowUploadVideoForLiveKP:{ systemName: "AllowUploadVideoForLiveKP", description:"Разрешить загружать видео для живого КП" },
        AllowCustomerMessaging:{ systemName: "AllowCustomerMessaging", description:"Разрешить чаты с клиентами"},
        AllowCreationMultipleSalesPriceTypes:{ systemName: "AllowCreationMultipleSalesPriceTypes", description: "Разрешить создание нескольких вариантов цены продажи" },
    }

    UserPermissionsDictionary = {
        IsAllowCustomersChatting:{systemName: "isAllowCustomersChatting", description:"Разрешить чаты с клиентами"},
    }


    orderStatuses = {
        Calculation: 1,
        Registration : 2,
        Complectation : 3,
        InProduction : 4,
        ProductionComplete : 5,
        Completed : 10,
        Failed : 11
    }

    //типы клиентов
    customerTypes = {
        //физическое лицо
        IndividualCustomer: {
            id: 0,
            systemName: "IndividualCustomer",
            name: "Физическое лицо",
            plural: "Физические лица"
        },
        //юридическое лицо
        LegalEntityCustomer: {
            id: 1,
            systemName: "LegalEntityCustomer",
            name: "Юридическое лицо",
            plural: "Юридические лица"
        },

        //партнёр
        DesignerCustomer: {
            id: 2,
            systemName: "DesignerCustomer",
            name: "Партнёр",
            plural: "Партнёры"
        },
    }
}

export default new Const();