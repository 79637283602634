import axios from 'axios';

class EmployeeService {
    getAllRoles() {
        return axios.get('User/GetAllRoles');
    }

    getCompanyEmployees(status='active', contractor='all') {
        return axios.get(`User/GetUsers?status=${status}&contractor=${contractor}`);
    }

    getCompanyEmployeesByCompanyId(companyId) {
        return axios.get(`User/GetUsersByCompany?companyId=${companyId}`);
    }    

    getCompanyEmployeesByRole(role) {
        return axios.get('User/GetUsersByRole?role=' + role);
    }

    getCompanyEmployeeById(id) {
        return axios.get('User/GetUserById?userid=' + id);
    }

    saveUser(data) {
        if (data.id)
            return this.editUser(data)
        else
            return this.createUser(data)
    }

    createUser(data) {
        return axios.post('User', data)
    }

    editUser(data) {
        return axios.put('User', data)
    }

    changePassword(data) {
        return axios.post('User/ChangeUserPassword', data)
    }

    changeMyPassword(data) {
        return axios.post('User/ChangeMyPassword', data)
    }

    getUserProfile(employeeId) {
        let url = 'User/GetUserProfile';
        if (employeeId)
        {
            url+='?id=' + employeeId;
        }
        return axios.get(url);
    }

    updateUserProfile(data) {
        return axios.put('User/UpdateUserProfile', data)
    }

    getProductionPerfomers() {
        return axios.get('Production/GetProductionPerfomers');
    }

    plugTelegram() {
        return axios.post("User/PlugTelegram")
    }

    unplugTelegram() {
        return axios.post("User/UnplugTelegram")
    }

    getCompanyEmployeePermissions() {
        return axios.get('User/GetUserPermissions');
    }
}

export default new EmployeeService();