<template>
  <b-modal :id="modalId" no-close-on-backdrop ref="modal" title-tag="h4" size="xl" hide-header
    modal-class="inmodal" footer-class="p-2" body-class="chat-modal-body">
    <template v-slot:modal-footer="{ cancel }">
      <b-button class="btn btn-secondary" @click="cancel()">Закрыть</b-button>
    </template>
      <iframe class="chat-frame" v-if="value" :src="value" allow="microphone *; clipboard-write *" ></iframe>
  </b-modal>
</template>

<script>
import ValidationField from "@/components/ValidationField.vue";
import ChatService from "@/services/chat.service"

export default {
  name: "ChatModalComponent",
  components: {
    ValidationField,
  },
  data() {
    return {
    };
  },
  props: ["value", "id"],
  methods: {

  },
  computed: {
    modalId() {
      return this.id ?? "chatModal";
    }
  }
}
</script>

<style>
.modal-body.chat-modal-body {
  padding: 0;
}
.chat-frame {
  margin-bottom: -7px;
  width: 100%;
  height: 70vh;
}
</style>