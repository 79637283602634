import axios from 'axios';
import CompanyService from "@/services/company.service";
import EmployeeService from "@/services/employee.service";
import jwt_decode from 'jwt-decode'

class AuthService {
    login(user) {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic UmVzb3VyY2VPd25lcjpzZWNyZXQ="
            }
        };
        var bodyFormData = new URLSearchParams();
        // bodyFormData.set("client_id", "ResourceOwner");
        // bodyFormData.set("client_secret", "secret");
        bodyFormData.set("grant_type", "password");
        bodyFormData.set("username", user.username);
        bodyFormData.set("password", user.password);
        // bodyFormData.set("scope", "roles openid api offline_access");
        return axios
            .post(process.env.VUE_APP_IdentityServerPath, bodyFormData, config)
            .then(this.handleResponse)
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem('auth-info', JSON.stringify(response.data));

                    const config = {
                        headers: {
                            "Authorization": "Bearer " + response.data.access_token
                        }
                    };
                    return axios.all([
                      CompanyService.getCompanyInfo(config),
                      EmployeeService.getCompanyEmployeePermissions(config)
                    ]).then(axios.spread((company, employeePermissions) => {
                        localStorage.setItem('company-info', JSON.stringify(company.data));
                        localStorage.setItem('user-permissions', JSON.stringify(employeePermissions.data));
                        return response.data;
                    }));
                }
            }).finally(() => {

            });
    }

    logout() {
        localStorage.removeItem('auth-info');
        localStorage.removeItem('company-info');
        localStorage.removeItem('user-info');
        localStorage.removeItem('user-permissions');
    }

    handleResponse(response) {
        if (response.status === 401) {
            this.logout();
            location.reload(true);

            const error = response.data && response.data.message;
            return Promise.reject(error);
        }

        return Promise.resolve(response);
    }
}

export default new AuthService();