<template>
<div>
  <b-button :id="buttonId" @click="showChat" variant="primary" size="sm">
    <i class="fa fa-comments-o"></i>
    Чаты
    <b-badge pill :variant="unansweredCount.color === 'red' ? 'danger' : 'secondary'">{{ unansweredCount.count }}</b-badge>
  </b-button>
  <ChatModalComponent v-model="iframeUrl" />
</div>
</template>

<script>
import ChatModalComponent from "@/components/Chat/ChatModal.vue";
import ChatService from "@/services/chat.service";

export default {
  name: "Chat",
  components: {
    ChatModalComponent
  },
  props: ["id"],
  data() {
    return {
      unansweredCount: {},
      polling: null,
      iframeUrl: null,
    };
  },
  methods: {
    showChat() {
      this.$bvModal.show("chatModal");
    },
    setPolling() {
      this.polling = setInterval(this.getChatUnansweredMessageCount, 10000);
    },
    getChatUnansweredMessageCount() {
      ChatService.getUserUnansweredCount().then(response => {
        this.unansweredCount = response.data;
      });
    }
  },
  computed: {
    buttonId() {
      return this.id ? this.id+"Button" : "chatButton";
    }
  },
  created() {
    this.getChatUnansweredMessageCount();
    this.setPolling();
    ChatService.getUserGlobalChatUrl()
      .then(response => {
        this.iframeUrl = response.data;
      });
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
}
</script>

<style scoped>

</style>