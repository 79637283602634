<template>
  <div id="app" v-bind:class="{ fullHeightApp: !isLoggedIn }">
    <div id="wrapper" v-if="isLoggedIn">
      <NavigationMenu />
      <div id="page-wrapper" class="gray-bg">
        <TopBar />
        <router-view :key="$route.fullPath" />
        <Footer />
      </div>
    </div>
    <div id="wrapper" v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import NavigationMenu from "@/components/NavigationMenu/NavigationMenu.vue";
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import update from "@/mixins/update";

export default {
  name: "app",
  components: {
    NavigationMenu,
    TopBar,
    Footer,
  },
  mixins: [update],
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created: function () {    
    if (!this.$store.state.auth.user) {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    }

    Axios.interceptors.request.use(
      function (config) {
        const authInfo = localStorage.getItem("auth-info");
        if (authInfo) {
          const token = JSON.parse(authInfo).access_token;
          config.headers.Authorization = "Bearer " + token;
        }
        return config;
      },
      function (error) {
        console.log(error);
        return Promise.reject(error);
      }
    );

    Axios.interceptors.response.use(null, (error) => {
      let path = "/error";
      if (error && error.response && error.response.status) {
        switch (error.response.status) {
          case 401:
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
            break;
          case 404:
            path = "/404";
            break;
        }
      }
      return Promise.reject(error);
    });
  },
  watch: {
    updateExists(val) {
      if (val)
        this.$swal({
          icon: "warning",
          title: "Доступно обновление",
          text: "Мы обновили CURS24: теперь работа с системой ещё проще и удобнее. Нажмите «Обновить», чтобы получить доступ к новым функциям прямо сейчас!",
          confirmButtonText: "Обновить",
        }).then((result) => {
          if (result.isConfirmed) {
            this.refreshApp();
          }
        });
    }
  }
};
</script>
<style>
.fullHeightApp {
  height: 100%;
}
</style>