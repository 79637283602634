<template>
  <b-badge v-if="count" pill variant="danger">{{ count }}</b-badge>
</template>

<script>
import LeadService from "@/services/lead.service";

export default {
  name: "LeadsCountBadge",
  data() {
    return {
      count: null,
      polling: null,
    };
  },
  methods: {
    setPolling() {
      this.polling = setInterval(this.getNewLeadsCount, 10000);
    },
    getNewLeadsCount() {
      LeadService.getLeadsStatistic().then(response => {
        this.count = response.data.newLeadsCount;
      });
    }
  },
  created() {
    this.getNewLeadsCount();
    this.setPolling();
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
}
</script>

<style scoped>

</style>