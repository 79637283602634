<template>
    <li :class="itemClass">
      <router-link :to="path" v-if="path">
        <i :class="icon"></i>
        <span class="nav-label">{{ title }}</span>

        <div v-if="$slots.badge" class="d-inline-block ml-1">
          <slot name="badge"></slot>
        </div>
      </router-link>
      <template v-else>
        <a href="#">
          <i :class="icon"></i>
          <span class="nav-label">{{ title }}</span>
          <span class="fa arrow"></span>
        </a>
        <ul class="nav nav-second-level collapse">
          <li v-for="subitem in children" :key="subitem.path">
            <router-link :to="subitem.path">
              {{ subitem.title }}
            </router-link>
          </li>
        </ul>
      </template>
    </li>
</template>

<script>
export default {
  name: "NavigationMenuItem",
  props: ["path", "title", "icon", "itemClass", "children"],
}
</script>



<style scoped>

</style>