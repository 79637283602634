import axios from "axios";

class LeadService {
    get(leadId) {
        return axios.get('Lead', {params: {leadId}});
    }

    getAll(data) {
        return axios.get("Lead/GetAll?viewType="+data.viewType);
    }

    setStatus(data) {
        return axios.post('Lead/SetStatus', data);
    }

    saveLead(data) {
        if (data.id) {
            return this.editLead(data);
        } else {
            return this.addLead(data)
        }
    }

    addLead(data) {
        return axios.post('Lead', data);
    }

    editLead(data) {
        return axios.put('Lead', data);
    }

    closeLeadFailure(data) {
        return axios.post('Lead/CloseLeadFailure', data);
    }

    closeLeadAccept(data) {

        return axios.post('Lead/CloseLeadAccept', data);
    }

    getLeadsStatistic() {
        return axios.get('Lead/GetLeadsStatistic');
    }
}

export default new LeadService();